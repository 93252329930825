<template>
  <div class="">
    <h2 class="text-myheader1 font-bold text-center mt-3">{{$t('howItWorks.title')}}</h2>
    <div
      class="my-container hero-main mx-auto relative overflow-hidden "
    >
      <img
        src="@/assets/images/how-it-works-2.svg"
        alt="Hero image what we do"
        class="w-full h-full object-contain"
      />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {title: "¿Qué hacemos?"},
}
</script>

<style>

</style>